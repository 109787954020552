//CP Colours
$default-color:                     #00A9E0;    $default-color2:                     #C7FFDA;
$pink:                              #D7263D;
$yellow:                            #FFFC31;
$acqua:                             #00FDDC;
$gradient-color1:                   #3d3393;    $gradient-color2:                    #2b76b9;
$gradient-color3:                   #2cacd1;    $gradient-color4:                    #35eb93;

// Basic Colours
$background-color:                  #ffffff;    $background-color2:                  #f7f7f7;
$heading-color:                     #222222;
$text-color:                        #303030;
$link-color:                        #00A9E0;

// Family Colours
$reach-color:                       #003349;    $reach-color2:                       #ffd100;
$reachadmin-color:                  #274C77;    $reachadmin-color2:                  #fca311;
$myfuture-color:                    #3e87cb;    $myfuture-color2:                    #fedb00;
$steps-color:                       #744FC6;    $steps-color2:                       #E4FF1A;
$steps-color3:                      #00A9E0;
// Sector Colors
$accountancy-color:                 #004866;    $accountancy-color2:                 #BDD9BF;
$agriculture-color:                 #81C340;    $agriculture-color2:                 #D3F9B5;
$business-color:                    #36A0CE;    $business-color2:                    #FED766;
$creative-arts-color:               #D13076;    $creative-arts-color2:               #87F5FB;
$government-color:                  #F29900;    $government-color2:                  #FFE74C;
$healthcare-color:                  #01C6B2;    $healthcare-color2:                  #FFD6A0;
$science-color:                     #5F4A8B;    $science-color2:                     #5EFC8D;

// Communities Colors
$school-color:                      #487B7C;    $school-color2:                      #7DD181;
$graduates-color:                   #B63437;    $graduates-color2:                   #84DCC6;
$adultlearner-color:                #DE800C;    $adultlearner-color2:                #1A535C;
$jobseeker-color:                   #1F8AF5;    $jobseeker-color2:                   #FFE74C;
$parents-color:                     #484F5B;    $parents-color2:                     #ED6A5A;
$guidanceprofessionals-color:       #354593;    $guidanceprofessionals-color2:       #E5E059;

// Interest Colors
$administrative-color:              #1B749A;
$creative-color:                    #D13076;
$enterprising-color:                #1BB3C0;
$investigative-color:               #5F4A8B;
$linguistic-color:                  #E2643B;
$naturalist-color:                  #708765;
$realist-color:                     #A97245;
$social-color:                      #63AA80;

// Section Colors
$apprenticeships-color:             #487B7C;    $apprenticeships-color2:            #E7E247;
//$careers-color:                     #B2945B;
//$careers-color2:                    #0A1128;
$careers-color:                     #2b76b9;    $careers-color2:                    #00FDDC;
$plc-color:                         #6260CB;    $plc-color2:                        #A30B37;
$cao-color:                         #1A8D9A;    $cao-color2:                        #FFBF00;
$wx-color:                          #FF6F61;    $wx-color2:                         #615B95;

// TourismCareers.ie
$tcpink:                            #ff3cdc;    $tcgreen:                            #05d205;
$tcblue:                            #005bff;    $tclightgreen:                       #00ff95;
$tcorange:                          #ff5100;    $tcwhite:                            #ffffff;
$tcgrey:                            #535353;    $tclightgrey:                        #f1f1ea;

// Indeed
$indeed:                            #2164F3;

// Junior Cycle Program
$junior-year1:                      #FF9E16;
$junior-year2:                      #0071CE;
$junior-year3:                      #6CC04A;

// Reach Apps Categories
$rappcat-planning:                  #4CB944;
$rappcat-assessment:                #EA3546;
$rappcat-study:                     #00A9E0;
$rappcat-work:                      #662E9B;
$rappcat-education:                 #F9C80E;
$rappcat-saoloibre:                 #F86624;
$rappcat-lcvp:                      #20a787;

// Events Categories Groups
$eventscatgroup1:                   #8393A7;
$eventscatgroup2:                   #0081D5;
$eventscatgroup3:                   #FF9947;
$eventscatgroup1:                   #A939B9;
$eventscatgroup2:                   #03BD5B;
$eventscatgroup2:                   #D1335B;

$sector-scheme: (
    1: ($agriculture-color, $agriculture-color2),
    2: ($science-color, $science-color2),
    3: ($healthcare-color, $healthcare-color2),
    4: ($creative-arts-color, $creative-arts-color2),
    5: ($government-color, $government-color2),
    6: ($accountancy-color, $accountancy-color2),
    7: ($business-color, $business-color2),
) !default;

$groups-scheme: (
    school: ($school-color, $school-color2),
    graduates: ($graduates-color, $graduates-color2),
    adultlearners: ($adultlearner-color, $adultlearner-color2),
    jobseekers: ($jobseeker-color, $jobseeker-color2),
    parents: ($parents-color, $parents-color2),
    guidanceprofessionals: ($guidanceprofessionals-color, $guidanceprofessionals-color2)
) !default;

$interest-scheme: (
    1: ($realist-color),
    2: ($administrative-color),
    3: ($enterprising-color),
    4: ($investigative-color),
    5: ($social-color),
    6: ($creative-color),
    7: ($linguistic-color),
    8: ($naturalist-color)
) !default;

$section-scheme: (
    apprenticeships:($apprenticeships-color, $apprenticeships-color2),
    careers:($careers-color, $careers-color2),
    plc:($plc-color, $plc-color2),
    cao:($cao-color, $cao-color2),
    wx: ($wx-color, $wx-color2)
) !default;

$eventscat-scheme: (
    0: (#8393A7),
    1: (#0081D5),
    2: (#FF9947),
    3: (#A939B9),
    4: (#03BD5B),
    5: (#D1335B)
) !default;

$reachappscategory: (
    1: (#4CB944),
    2: (#EA3546),
    3: (#00A9E0),
    4: (#F9C80E),
    5: (#662E9B),
    6: (#20a787),
    7: (#F86624),
) !default;

$juniorcycleprogram: (
    1: (#FF9E16),
    2: (#0071CE),
    3: (#6CC04A),
) !default;