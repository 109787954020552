
.fieldset{
    .legend{
        font-family: $heading-font-family;
        font-weight: $heading-font-weight;
        text-transform: uppercase;
        font-size: .75em;
        margin-bottom: 0.5em;
    }
    .field:not(:last-child){
        margin-bottom: .25em;
    }
}
.field {
    position: relative;
    max-width: 100%;
    small{
        display: block;
        margin-bottom: .35em;
    }
    &--text,
    &--select,
    &--submit {
        textarea,
        input,
        select {
            max-width: 100%;
            box-sizing: border-box;
            border: 1px solid #ddd;
            background: #f7f7f7;
            padding: 0.75em 1.25em;
            outline: 0;
            border-radius: .5em;
            color: $text-color;
            box-shadow: none;
            &:focus {
                border-color: $default-color;
                & + label{
                    color: $default-color;
                }
            }
        }
    }
    &--text,
    &--select {
        display: flex;
        flex-wrap: wrap;
        &>*{
            flex: 0 0 100%;
            order: 2;
        }
        .tox + label,
        textarea + label,
        input + label,
        select + label {
            margin-bottom: .5em;
            order: 1;
            font-family: $heading-font-family;
            font-weight: $heading-font-weight;
            font-size: .75em;
            text-transform: uppercase;
        }
        textarea,
        input,
        select {
            width: 100%;
        }
    }
    &--select {
        select {
            appearance: none;
            padding-right: 2.25em;
            &::-ms-expand {
                display: none;
            }
        }
        &:after {
            pointer-events: none;
            content: '\f2f9';
            font-family: $zmdi;
            position: absolute;
            bottom: .25em;
            right: .5em;
            font-size: 1.5em;
            color: $default-color;
        }
    }
    &--radio,
    &--checkbox {
        input {
            display: none;
            &+label {
                cursor: pointer;
                display: flex;
                align-items: center;
                &:before {
                    font-family: $zmdi;
                    font-size: 1.25em;
                    margin-right: .35em;
                }
            }
            &:checked+label {
                color: $default-color;
            }
        }
    }
    &--slider{
        margin-bottom: .5em;
        label{
            display: block;
            font-family: $heading-font-family;
            font-weight: $heading-font-weight;
            font-size: .75em;
            text-transform: uppercase;
        }
        input{
            margin-top: .5em;
        }
        @supports not (-ms-high-contrast: none) {
            input {
                -webkit-appearance: none;
                width: 100%;
                height: .5em;
                border-radius: 5px;
                background: #ddd;
                outline: none;
                opacity: 0.7;
                -webkit-transition: .2s;
                transition: opacity .2s;
                &::-webkit-slider-thumb {
                    -webkit-appearance: none;
                    appearance: none;
                    width: 1.25em;
                    height: 1.25em;
                    border-radius: 50%;
                    background: $default-color;
                    cursor: pointer;
                }
                &::-moz-range-thumb {
                    width: 1.25em;
                    height: 1.25em;
                    border-radius: 50%;
                    background: $default-color;
                    cursor: pointer;
                }
            }
        }
    }
    &--radio {
        input {
            &+label:before {
                content: '\f26c';
            }
            &:checked+label:before {
                content: '\f26f';
            }
        }
    }
    &--checkbox {
        input {
            &+label:before {
                content: '\f279';
            }
            &:checked+label:before {
                content: '\f26a';
            }
        }
    }
    &--file{
        position: relative;
        input{
            position: absolute;
            cursor: pointer;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            opacity: 0;
            &:focus{
                outline: none;
            }
            &+label{
                border-radius: .5em;
                text-align: center;
                border: .25em dashed #ddd;
                padding: 3em 1em;
                display: block;
                &:before{
                    content: '\f220';
                    font-family: $zmdi;
                    display: block;
                    font-size: 3em;
                }
            }
        }
        &.draging input+label{
            border-color: $default-color;
            background: rgba($default-color, 0.15);
        }
    }
    &--toggle {
        input {
            display: none;
            &+label {
                position: relative;
                cursor: pointer;
                display: flex;
                align-items: center;
                &:before{
                    content: '';
                    background: #ddd;
                    padding: 0;
                    width: 2.5em;
                    height: 1.5em;
                    border-radius: 1em;
                    margin-right: 0.5em;
                }
                &:after{
                    content: '';
                    width: 1em;
                    height: 1em;
                    background: #fff;
                    border-radius: 100%;
                    position: absolute;
                    left: .25em;
                    top: 50%;
                    transform: translateY(-50%);
                    transition: all 150ms ease-in-out;
                }
            }
            &:checked+label{
                &:before{
                    background: $default-color;
                }
                &:after{
                    left: 1.25em;
                }
            }
        }
        &.hide-select{
            .select{
                margin-top: .5em;
                position: relative;
                select {
                    width: 100%;
                    max-width: 100%;
                    box-sizing: border-box;
                    border: 1px solid #ddd;
                    background: #f7f7f7;
                    padding: 0.75em 1.25em;
                    outline: 0;
                    border-radius: .5em;
                    color: $text-color;
                    box-shadow: none;
                    &:focus {
                        border-color: $default-color;
                        & + label{
                            color: $default-color;
                        }
                    }
                }
                select {
                    appearance: none;
                    padding-right: 2.25em;
                    &::-ms-expand {
                        display: none;
                    }
                }
                &:after {
                    pointer-events: none;
                    content: '\f2f9';
                    font-family: $zmdi;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 1em;
                    font-size: 1.5em;
                    color: $default-color;
                }
            }
            input{
                &  ~ .select{
                    display: none;
                }
                &:checked ~ .select{
                    display: block;
                }
            }
        }
    }
    &--submit {
        border-top: 1px solid #ddd;
        padding-top: 1em;
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
        align-items: center;
        &>*:not(:first-child) {
            margin-right: 1em;
        }
        input {
            cursor: pointer;
            font-family: $heading-font-family;
            font-weight: $heading-font-weight;
            text-transform: uppercase;
            font-size: 0.85em;
            padding: 0.8em 1.5em;
            letter-spacing: .15em;
            background: $default-color;
            color: #fff;
            border-color: $default-color;
            transition: all 150ms ease-in-out;
            border-radius: 2em;
            &:hover,
            &:focus {
                letter-spacing: .25em;
                padding: 0.8em 1.25em;
                border-color: $default-color;
            }
            &.danger{
                background: $pink;
                border-color: $pink;
                &:hover,
                &:focus{
                    border-color: $pink;
                }
            }
        }
        &>ul {
            list-style: none;
            margin: 0;
            padding: 0;
            font-size: .85em;
            display: flex;
            align-items: center;
            li {
                &:not(:last-child) {
                    margin-right: 1em;
                }
                a {
                    color: $text-color;
                    &:focus,
                    &:hover {
                        color: $default-color;
                    }
                }
            }
        }
    }
}