// settings
@import 'settings/mixins';
@import 'settings/colors';
@import 'settings/fonts';
@import 'settings/icons';
@import 'settings/typography';
@import 'settings/variables';

:root {
  --searchbar-bg-color: 61, 51, 147; //#3d3393
  --searchbar-accent-color: 0, 253, 220; //#00FDDC
  --searchbar-tab-txt-color: 61, 51, 147; //#3d3393
  --searchbar-tab-bg-color: 0, 253, 220; //#00FDDC
  --searchbar-tab-active-txt-color: 61, 51, 147; //#3d3393
  --searchbar-tab-active-bg-color: 255, 255, 255; //#FFFFFF
}
.tab-01,
[value='tab-01'] {
  --background-color: 31, 138, 245; // #1F8AF5
  --light-color: 177, 215, 252; // #b1d7fc
  --foreground-color: 255, 255, 255;
}
.tab-02,
[value='tab-02'] {
  --background-color: 43, 118, 185; // #B63437 #2b76b9
  --light-color: 177, 215, 252; // #e49fa1 #b1d7fc
  --foreground-color: 255, 255, 255;
}
.tab-03,
[value='tab-03'] {
  --background-color: 72, 123, 124; // #487B7C
  --light-color: 153, 195, 196; // #99c3c4
  --foreground-color: 255, 255, 255;
}
.tab-04,
[value='tab-04'] {
  --background-color: 53, 69, 147; // #354593
  --light-color: 140, 152, 213; // #8c98d5
  --foreground-color: 255, 255, 255;
}
.tab-05,
[value='tab-05'] {
  --background-color: 222, 128, 12; // #DE800C
  --light-color: 249, 199, 138; // #f9c78a
  --foreground-color: 255, 255, 255;
}

.searchbox.tabs {
  @import 'bootstrap/grid';
  @import 'components/fields';
  position: relative;
  .container {
    max-width: 1280px;
    padding: 0;
  }
  .grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 0rem;
    @include media('phablet', min) {
      gap: 0.25rem;
    }
  }
  .triggers button {
    cursor: pointer;
    font-family: $heading-font-family;
    font-weight: $heading-font-weight;
    border: 0;
    outline: none;
    box-shadow: 0;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    background: rgba(var(--searchbar-tab-bg-color), 1);
    color: rgba(var(--searchbar-tab-txt-color), 1);
    font-size: 0.7rem;
    padding: 1rem 0.5rem;
    &.active {
      background: rgba(var(--searchbar-tab-active-bg-color), 1);
      color: rgba(var(--background-color), 1);
    }
    @include media('tablet', min) {
      font-size: 0.95rem;
      padding: 1rem 1.5rem;
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
    }
  }
  .targets [class^='tab-'] {
    display: none;
    background: #fff;
    color: $text-color;
    padding: 1rem;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    box-shadow: 0 1rem 2rem 0 rgba(#000, 0.15);
    @include media('tablet', min) {
      padding: 1.5rem;
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 1rem;
    }
    &.active {
      display: block;
    }
    .field--text,
    .field--select {
      select,
      input {
        background: #f7f7f7;
        color: $text-color;
        border-color: rgba(var(--light-color), 1);
        &:focus {
          background: rgba(var(--light-color), 0.25);
        }
        &::placeholder {
          color: $text-color;
        }
      }
    }
    .field--select:after {
      color: rgba(var(--background-color), 1);
    }
    .field--submit {
      border: 0;
      padding: 0;
      input[type='submit'] {
        width: 100%;
        background: rgba(var(--background-color), 1);
        border: 1px solid rgba(var(--background-color), 1);
        border-radius: 0.5rem;
        color: #fff;
      }
    }
    .gsc-search-box {
      margin: 0;
      table {
        tbody {
          tr {
            display: flex;
            align-items: center;
            @include media('tablet-wide', max) {
              flex-wrap: wrap;
            }
          }
        }
      }
      .gsc-input {
        flex: 1;
        width: 100%;
        @include media('tablet-wide', max) {
          min-width: 100%;
          flex: 0;
          padding-right: 0;
        }
        _:-webkit-full-screen:not(:root:root),
        & {
          /*
            Define here the CSS styles applied only to the Safari 9 version when
            not running in iOS devices (iPhone, iPad, ...)
            */
          flex: 5.5;
        }
        .gsc-input-box {
          box-sizing: border-box;
          width: 100%;
          border-color: rgba(var(--light-color), 1);
          background: #f7f7f7;
          padding: 0.75rem 1.25rem;
          outline: 0;
          border-radius: 0.5rem;
          color: $text-color;
          table {
            tbody {
              tr {
                display: flex;
                align-items: center;
              }
            }
          }
          @include media('tablet-wide', max) {
            margin-bottom: 0.5rem;
          }
          &:hover {
            background: rgba(var(--light-color), 0.25);
          }
          .gsib_a {
            padding: 0;
            flex: 1;
            input {
              background: transparent;
              color: $text-color;
              font-size: 1em;
            }
          }
          .gsst_a {
            display: flex;
            align-items: center;
            padding: 0;
            .gscb_a {
              line-height: 0;
              color: rgba(var(--background-color), 1);
              &:hover,
              &:focus {
                color: rgba(var(--background-color), 1);
              }
            }
          }
        }
      }
      .gsc-search-button {
        width: auto;
        flex: 0;
        @include media('tablet-wide', max) {
          min-width: 100%;
        }
        _:-webkit-full-screen:not(:root:root),
        & {
          /*
            Define here the CSS styles applied only to the Safari 9 version when
            not running in iOS devices (iPhone, iPad, ...)
            */
          flex: 1;
        }
        .gsc-search-button-v2 {
          white-space: nowrap;
          box-sizing: border-box;
          width: 100%;
          border: 1px solid rgba(var(--background-color), 1);
          outline: 0;
          border-radius: 0.5rem;
          cursor: pointer;
          font-family: $heading-font-family;
          font-weight: $heading-font-weight;
          text-transform: uppercase;
          font-size: 0.85rem;
          padding: 0.8rem 1.5rem;
          letter-spacing: 0.15rem;
          background: rgba(var(--background-color), 1);
          color: #fff;
          transition: all 150ms ease-in-out;
          &:hover {
            letter-spacing: 0.25rem;
          }
          &:focus {
            border-color: rgba(var(--background-color), 1);
            letter-spacing: 0.25rem;
            box-shadow: none;
          }
          svg {
            display: none;
          }
          &:after {
            content: 'Search Now';
          }
        }
      }
    }
  }
}
